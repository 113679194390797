import React from 'react';
import './styles.css';
import ConversationSimulator from './ConversationSimulator';

const ApplyingExpertise = () => {
  return (
    <div className="expertise-section">
      <h2>Exploring the State of the Art Conversational Planning Layer</h2>
      <p className="section-intro">
        In this section, I examine how Hippocratic AI approaches developing complex prompts for healthcare conversations within the state-of-the-art conversation planning layer of the Polaris 2.0 architecture.
      </p>
      
      <div className="subsection">
        <h3>Why The Need for a Sophisticated Approach to Healthcare AI</h3>
        <div className="expertise-content">
          <p>
            The necessity for a sophisticated approach to healthcare conversations arises from the inherently high stakes and intricate nature of the medical domain, where inaccuracies or oversights can lead to significant negative consequences for patients. Unlike many other applications of Large Language Models (LLMs), healthcare demands unwavering accuracy, a deep understanding of complex medical concepts, and the ability to interact with patients in a way that builds trust and ensures safety.
          </p>
          <p>
            Based on my research, I've identified several reasons why a simple, general-purpose AI is insufficient for effective and safe healthcare conversations:
          </p>
          <ul className="feature-list">
            <li><strong>Critical Need for Accuracy and Reliability:</strong> Healthcare decisions are based on precise information. Misinterpretations of symptoms, incorrect advice on treatments, or failures to recognize critical warning signs can have severe, even fatal, implications. Therefore, AI systems in this domain must be highly reliable and minimize the risk of hallucinations or factual errors. Polaris 2.0's design, with its focus on safety and verification through specialized agents, directly addresses this need.</li>
            
            <li><strong>Complexity of Medical Knowledge and Reasoning:</strong> Medicine encompasses a vast and constantly evolving body of knowledge, including intricate relationships between conditions, treatments, medications, and individual patient factors. General-purpose LLMs, trained on broad internet data, may lack the depth and specificity of medical knowledge required for accurate reasoning in specialized use cases.</li>
            
            <li><strong>Importance of Patient-Centered Communication:</strong> Effective healthcare involves more than just dispensing medical information. It requires empathy, rapport-building, and a patient-friendly communication style to ensure patients feel safe, supported, and confident in their care. These qualities can lead to better patient satisfaction and improved health outcomes.</li>
            
            <li><strong>Real-time Conversational Demands:</strong> Many critical healthcare interactions occur in real-time, such as phone calls. This imposes constraints on latency, voice quality, and the ability to handle interruptions and the nuances of spoken language, including errors from Automatic Speech Recognition (ASR).</li>
            
            <li><strong>Navigating Diverse Healthcare Scenarios:</strong> Healthcare conversations can cover a wide range of topics, from medication adherence and lab result discussions to procedural logistics and wellness check-ins. A sophisticated system must be versatile and capable of handling these diverse scenarios while maintaining medical accuracy.</li>
            
            <li><strong>Addressing Healthcare Workforce Shortages:</strong> The healthcare industry faces significant staffing shortages. AI agents have the potential to augment the healthcare workforce by handling routine, non-diagnostic communications, allowing human providers to focus on more critical tasks.</li>
          </ul>
        </div>
      </div>
      
      <div className="subsection">
        <h3>Defining the SOTA Conversation Planning Layer in Healthcare</h3>
        <div className="expertise-content">
          <p>
            The "SOTA conversation planning layer" in Polaris is the sophisticated system that governs how the primary conversational agent interacts with the patient and how it leverages the specialist support agents (like the Medication Specialist, EHR Specialist, and potentially the Human Intervention Specialist and Checklist Specialist) to achieve specific healthcare goals. This layer involves:
          </p>
          <ul className="feature-list">
            <li><strong>Triggering Specialist Agents:</strong> Identifying when the conversation necessitates the expertise of a specific support agent (e.g., the patient mentioning a medication triggers the Medication Specialist).</li>
            <li><strong>Message Passing:</strong> Facilitating the exchange of information between the primary agent and the support agents through well-defined prompts and structured outputs.</li>
            <li><strong>State Management:</strong> Maintaining the conversation's context and the status of healthcare-related tasks (potentially guided by the Checklist Specialist).</li>
            <li><strong>Orchestrating Responses:</strong> Ensuring the primary agent delivers a coherent, patient-friendly, and medically accurate response by integrating the insights from the support agents.</li>
          </ul>
        </div>
      </div>
      
      <div className="subsection">
        <h3>Demonstration: State-Of-The-Art Conversation Planning in Action</h3>
        <div className="expertise-content">
          <p>
            To illustrate how the SOTA conversation planning layer works in practice, I've created an interactive demonstration below. This example follows a patient-AI conversation about health complications, showing how different specialist agents are triggered based on the conversation flow, and how prompts are dynamically generated to ensure safe, accurate responses.
          </p>
          <p>
            The scenario showcases a medical interaction where the system must verify dosages, check for contraindications, and communicate findings appropriately to the patient.
          </p>
        </div>
        
        {/* Add the Conversation Simulator component */}
        <ConversationSimulator />
      </div>
    </div>
  );
};

export default ApplyingExpertise; 