import React from 'react';
import { SocialIcon } from 'react-social-icons';
import pfp from '../imgs/pfp.png'
import oval from '../imgs/oval.png'

export function AboutPage() {
    return (
        <div className="bg-[#F5F4EC] min-h-screen pt-24 pb-16">
            <div className='flex flex-col items-center mx-4 sm:mx-8 md:mx-16 lg:mx-24 space-y-10 max-w-7xl xl:mx-auto'>
                <div className='flex flex-col space-y-10 md:flex-row md:space-x-10 md:space-y-0 md:items-end w-full max-w-3xl'>
                    <img src={pfp} alt="Profile" className='h-[280px] object-contain' />
                    <div className='flex flex-col space-y-8'>
                        <div>
                            <h1 className='text-2xl font-sans font-semibold mb-3 text-[rgb(10,11,87)]'>
                                Hi, I'm Sathvik!
                            </h1>
                            <h2 className='font-sans text-xl text-[rgb(10,11,87)]'>
                                I'm a developer and AI enthusiast grad from
                                <span className='font-medium'> Computer Science & Statistics @ University of Illinois at Urbana-Champaign</span>.
                            </h2>
                        </div>
                        <div className='space-y-3 font-sans text-[rgb(10,11,87)]'>
                            <div>
                                <h3 className='font-medium text-base'>CURRENTLY LEARNING</h3>
                                <p className='text-lg'>Go</p>
                            </div>
                            <div>
                                <h3 className='font-medium text-base'>CURRENTLY READING</h3>
                                <p className='text-lg'>Obstacle Is The Way by Ryan Holiday</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full max-w-4xl'>
                    <div className='font-sans mb-10 flex flex-col space-y-10 items-center md:items-start md:flex-row md:space-x-10 md:space-y-0 md:justify-between'>
                        <div className='flex flex-col space-y-4 leading-6 text-[16px] text-[rgb(10,11,87)]'>
                            <div>
                                <h3 className='font-bold text-lg mb-2'>RELEVANT COURSEWORK</h3>
                                <ul className='list-disc list-inside space-y-1'>
                                    <li>DD2257 - Data Visualization (Figma, Python, HTML/CSS, JS)</li>
                                    <li>STAT 440 - Statistical Data Management (Python, R)</li>
                                    <li>CS222 Software Design (Java, Figma, JS)</li>
                                    <li>CS411 - Database Systems (SQL)</li>
                                    <li>CS340 - Computer Systems (C++, Python)</li>
                                    <li>STAT426 - Statistical Modeling II (R)</li>
                                    <li>CS225 - Data Structures & Algorithms (C++)</li>
                                    <li>ECON 302 - Inter Microeconomic Theory</li>
                                    <li>ECON 203 - Economic Statistics II</li>
                                    <li>CS440 - Artificial Intelligence (Python)</li>
                                    <li>CS 421 - Programming Languages & Compilers (OCaml)</li>
                                    <li>CS440 - Numerical Methods I (Python)</li>
                                    <li>STAT 410 - Statistics and Probability II (R)</li>
                                </ul>
                            </div>
                        </div>
                        <div className=''>
                            <img src={oval} alt="Oval" className='max-h-[320px] items-center object-contain' />
                        </div>
                    </div>
                    <div className=''>
                        <h3 className='mb-4 font-sans font-bold text-lg py-2 border-b-[1px] border-[rgb(10,11,87)] text-[rgb(10,11,87)]'>
                            @ HOME
                        </h3>
                        <div className='flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-10 mb-10'>
                            <div className='space-y-3 font-sans text-[rgb(10,11,87)]'>
                                <div>
                                    <h4 className='font-bold text-base'>HOME</h4>
                                    <p className='text-lg'>Chicago</p>
                                </div>
                                <div>
                                    <h4 className='font-bold text-base'>HOBBIES</h4>
                                    <p className='text-lg'>Lifting, Entrepreneurship, Triathlons, Photography, Podcasts, Reading</p>
                                </div>
                            </div>
                            <div className='font-sans text-lg space-y-3 text-[rgb(10,11,87)]'>
                                <p>Throughout my whole life, I've always been interested in creating products, both physical and digital, through engineering. Outside of that, I also love photography and athletics.</p>
                                <p>As of recently, I am training for an Ironman in New York, while also working on some side projects and re-learning the piano!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}