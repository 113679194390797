import React, { useState } from 'react';
import './styles.css';
import './WorkflowSection.css';
import PolarisArchitectureDiagram from './PolarisArchitectureDiagram';

const UnderstandingStrategy = () => {
  const [activeTab, setActiveTab] = useState('medical');

  return (
    <div className="strategy-section">
      <h2 className="strategy-title">
        Understanding Hippocratic AI's Prompting Strategy & Flow
      </h2>
      
      <div className="subsection intro-subsection">
        <h3>Introduction</h3>
        <div className="content">
          <div className="intro-content">
            <p>
              Hippocratic AI's prompting strategy is a cornerstone of its safety-focused LLM constellation, Polaris 2.0. 
              Through my research of their recently published paper, I've discovered how their sophisticated approach 
              leverages multiple specialized LLM agents working in concert to achieve remarkable results in healthcare conversations.
            </p>
            <p>
              The system employs a primary agent that directly interacts with users, while simultaneously engaging support 
              agents based on conversation context. These specialized agents provide domain-specific information and guidance 
              to the primary agent, creating a comprehensive, accurate, and patient-centered experience.
            </p>
            <p className="highlight-text">
              This multi-agent architecture demonstrates a deep understanding of safe, reliable, and domain-specific LLM prompting 
              that prioritizes medical accuracy while maintaining natural, empathetic communication.
            </p>
          </div>
        </div>
      </div>
      
      <div className="architecture-diagram-container">
        <h3 className="diagram-title">Polaris Architecture Visualization</h3>
        <PolarisArchitectureDiagram />
      </div>
      
      <div className="subsection">
        <h3>Prompting Objectives</h3>
        <div className="content">
          <div className="objective-tabs">
            <button 
              className={`tab-button ${activeTab === 'medical' ? 'active' : ''}`}
              onClick={() => setActiveTab('medical')}
            >
              Medical Accuracy
            </button>
            <button 
              className={`tab-button ${activeTab === 'contextual' ? 'active' : ''}`}
              onClick={() => setActiveTab('contextual')}
            >
              Contextual Precision
            </button>
            <button 
              className={`tab-button ${activeTab === 'task' ? 'active' : ''}`}
              onClick={() => setActiveTab('task')}
            >
              Task Guidance
            </button>
            <button 
              className={`tab-button ${activeTab === 'empathy' ? 'active' : ''}`}
              onClick={() => setActiveTab('empathy')}
            >
              Empathy
            </button>
            <button 
              className={`tab-button ${activeTab === 'domain' ? 'active' : ''}`}
              onClick={() => setActiveTab('domain')}
            >
              Domain Knowledge
            </button>
            <button 
              className={`tab-button ${activeTab === 'realtime' ? 'active' : ''}`}
              onClick={() => setActiveTab('realtime')}
            >
              Real-time Response
            </button>
          </div>

          <div className="tab-content">
            {activeTab === 'medical' && (
              <div className="objective-content">
                <h4>
                  Medical Accuracy and Safety
                </h4>
                <p>
                  The paramount objective of Hippocratic AI's prompting strategy is to provide medically sound information 
                  while avoiding potentially harmful or incorrect advice. Their multi-agent system substantially reduces 
                  hallucinations by having specialist support models supervise the primary LLM.
                </p>
                <ul className="content-list">
                  <li>
                    Specialist agents like the Medication Specialist verify prescription adherence and identify potential 
                    OTC contraindications, ensuring the primary agent doesn't provide unsafe medication advice.
                  </li>
                  <li>
                    The Labs & Vitals Specialist provides accurate reference ranges and plausibility checks for 
                    patient-reported lab values.
                  </li>
                  <li>
                    Proprietary data, clinical guidelines, and physician-approved reference tables train both the 
                    foundation model and specialist agents, ensuring responses are grounded in medical knowledge.
                  </li>
                  <li>
                    Built-in guardrails, such as the Human Intervention Specialist, are triggered by prompts that detect 
                    potentially critical situations, ensuring human supervisors can intervene when necessary.
                  </li>
                </ul>
              </div>
            )}

            {activeTab === 'contextual' && (
              <div className="objective-content">
                <h4>
                  Contextual Precision
                </h4>
                <p>
                  Hippocratic AI's prompts maintain comprehensive understanding of long, multi-turn conversations, 
                  ensuring responses remain relevant to the evolving context.
                </p>
                <ul className="content-list">
                  <li>
                    The system maintains a conversation history (Ht) and an internal state (St) that evolves based on 
                    the primary agent's interactions and support agents' outputs.
                  </li>
                  <li>
                    Agent-specific prompts (PAi(Ht)) derive from this conversation history, allowing each agent to 
                    consider past interactions when formulating outputs.
                  </li>
                  <li>
                    The Checklist Specialist plays a crucial role in maintaining context by tracking completed and 
                    pending tasks, influencing the primary agent's prompt to ensure all call objectives are met over 
                    potentially long conversations.
                  </li>
                  <li>
                    Even when patients go on tangential discussions, the Checklist Specialist ensures the primary agent 
                    returns to pending checklist items.
                  </li>
                </ul>
              </div>
            )}

            {activeTab === 'task' && (
              <div className="objective-content">
                <h4>
                  Task-Oriented Guidance
                </h4>
                <p>
                  Prompts are engineered to guide the primary agent through complex care protocols and checklists, 
                  ensuring all necessary objectives of a call are addressed.
                </p>
                <ul className="content-list">
                  <li>
                    Short-horizon tasks (Tt+1) generated by support agents are appended to the primary agent's prompt 
                    based on state changes.
                  </li>
                  <li>
                    For example, if the Medication Specialist detects a dosage discrepancy, it injects a task into the 
                    primary agent's prompt to address this with the patient.
                  </li>
                  <li>
                    The system uses deterministic imperative programming to mediate message passing between agents, 
                    ensuring tasks given to the primary agent are systematically formatted.
                  </li>
                  <li>
                    When applicable, the system synthesizes information from multiple agents to create comprehensive 
                    guidance for the primary agent.
                  </li>
                </ul>
              </div>
            )}

            {activeTab === 'empathy' && (
              <div className="objective-content">
                <h4>
                  Empathetic and Trustworthy Interactions
                </h4>
                <p>
                  Prompts encourage the primary agent to exhibit "bedside manner," empathy, and rapport-building skills, 
                  fostering a positive patient experience.
                </p>
                <ul className="content-list">
                  <li>
                    The primary agent is trained on organic healthcare conversations and simulated ones between patient 
                    actors and experienced care-management nurses.
                  </li>
                  <li>
                    This training helps the agent learn to speak like medical professionals and build rapport effectively.
                  </li>
                  <li>
                    Reinforcement Learning from Human Feedback (RLHF), involving healthcare professionals, refines the 
                    model's performance across key dimensions like empathy and conversational quality.
                  </li>
                  <li>
                    Nurses provide preference feedback on different candidate responses, prioritizing empathy and bedside 
                    manner alongside medical safety.
                  </li>
                </ul>
              </div>
            )}

            {activeTab === 'domain' && (
              <div className="objective-content">
                <h4>
                  Specialized Domain Knowledge Application
                </h4>
                <p>
                  Prompts are designed to trigger and leverage the expertise of specialized support agents for tasks 
                  requiring in-depth medical knowledge.
                </p>
                <ul className="content-list">
                  <li>
                    The primary agent's prompt indirectly triggers support agents by containing user utterances and the 
                    current conversation state.
                  </li>
                  <li>
                    Each support agent has its own prompt (PAi) derived from the conversation history.
                  </li>
                  <li>
                    Based on conversation content, relevant support agents are activated. For instance, mentioning a 
                    medication triggers the Medication Specialist, while discussing lab results engages the Labs & 
                    Vitals Specialist.
                  </li>
                  <li>
                    These specialist agents process their specific prompts and provide structured outputs (OAi) containing 
                    relevant information and tasks back to the primary agent.
                  </li>
                  <li>
                    For example, if a patient mentions taking dulaglutide and provides an HbA1c value, the Lab & Vitals 
                    Specialist and the Medication Specialist collaborate to provide a comprehensive response explaining 
                    the relationship between the medication and the lab result.
                  </li>
                </ul>
              </div>
            )}

            {activeTab === 'realtime' && (
              <div className="objective-content">
                <h4>
                  Efficient Real-time Response
                </h4>
                <p>
                  The system aims for low latency to facilitate real-time voice conversations.
                </p>
                <ul className="content-list">
                  <li>
                    The LLM constellation architecture allows all agents in Polaris to run concurrently, including the 
                    primary agent and support agents, helping to reduce end-to-end latency.
                  </li>
                  <li>
                    Support agents can operate in synchronous or asynchronous modes with respect to the primary agent.
                  </li>
                  <li>
                    Synchronous agents provide context that the primary agent's response is immediately conditioned on, 
                    while asynchronous agents provide context for subsequent turns.
                  </li>
                  <li>
                    Polaris implements a form of 'garbage collection' on support agent messages to prevent the primary 
                    agent from being overburdened with outdated instructions.
                  </li>
                  <li>
                    The constellation is pre-emptible with respect to new user utterances, allowing for immediate 
                    processing of updated information.
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="subsection workflow-subsection">
        <h3>Prompting Flow</h3>
        <div className="content">
          <p className="workflow-intro">
            Through my analysis, I've identified that the prompting flow within the Polaris system follows a sophisticated algorithm that coordinates 
            multiple agents to deliver accurate, contextually relevant responses:
          </p>
          
          <div className="workflow-steps">
            {[
              {
                step: 1,
                title: 'Initialization',
                description: <>The system receives a user utterance and has a current conversation history (H<sub>t</sub>) and state (S<sub>t</sub>).</>
              },
              {
                step: 2,
                title: 'Support Agent Prompting',
                description: <>For each support agent (A<sub>2</sub> to A<sub>N</sub>), a support agent prompt (P<sub>Ai</sub>) is created based on the current conversation history and state.</>
              },
              {
                step: 3,
                title: 'Support Agent Processing',
                description: <>Each support agent processes its prompt and generates an output (O<sub>Ai</sub>), which is a structured set of fields and values.</>
              },
              {
                step: 4,
                title: 'Output Processing',
                description: <>The output of each support agent is processed to update the system's temporary state (S<sub>temp</sub>) and extract agent-specific tasks (T<sub>Ai</sub>).</>
              },
              {
                step: 5,
                title: 'Task Aggregation',
                description: <>All extracted tasks (T<sub>Ai</sub>) are aggregated into a set of short-horizon tasks (T<sub>t+1</sub>) for the primary agent.</>
              },
              {
                step: 6,
                title: 'State Update',
                description: <>The system's state is updated (S<sub>t+1</sub>) based on the processed outputs of the support agents.</>
              },
              {
                step: 7,
                title: 'Primary Agent Prompting',
                description: <>A primary agent prompt (P) is created based on the updated state, the conversation history, and the aggregated short-horizon tasks.</>
              },
              {
                step: 8,
                title: 'Primary Agent Response',
                description: <>The primary agent processes its prompt and generates a response (r<sub>t+1</sub>).</>
              },
              {
                step: 9,
                title: 'History Update',
                description: <>The conversation history is updated to include the user's utterance and the primary agent's response (H<sub>t+1</sub>).</>
              },
              {
                step: 10,
                title: 'Return',
                description: <>The updated conversation history, state, and primary agent response are returned.</>
              }
            ].map((step) => (
              <div key={step.step} className="workflow-step">
                <div className="step-number">{step.step}</div>
                <div className="step-content">
                  <h4 className="step-title">{step.title}</h4>
                  <p className="step-description">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
          
          <p className="workflow-conclusion">
            I'm impressed by how this intricate prompting strategy and flow enable Polaris 2.0 to engage in safe, accurate, and 
            patient-centric healthcare conversations. It's a remarkable achievement in leveraging the collective intelligence of 
            a multi-agent LLM constellation to solve real-world healthcare challenges.
          </p>
        </div>
      </div>
      
      <div className="subsection conclusion-subsection">
        <h3>Conclusion</h3>
        <div className="content">
          <div className="conclusion-grid">
            <div className="conclusion-card">
              <h4>Advanced Healthcare AI</h4>
              <p>
                Hippocratic AI's prompting strategy represents a significant advancement in healthcare AI. I'm particularly impressed by how it orchestrates 
                specialized LLM agents through sophisticated prompting techniques that could transform patient interactions.
              </p>
            </div>
            
            <div className="conclusion-card">
              <h4>Specialized Knowledge</h4>
              <p>
                I find the multi-agent approach fascinating as it allows for specialized knowledge to be applied precisely when needed, while the 
                primary agent maintains a coherent conversation flow - a balance that's notoriously difficult to achieve.
              </p>
            </div>
            
            <div className="conclusion-card">
              <h4>Future Implications</h4>
              <p>
                Looking beyond the current implementation, I believe this architecture points toward a future where thoughtful prompting strategies can overcome 
                limitations of single-agent LLM systems across many high-stakes domains beyond healthcare.
              </p>
            </div>
          </div>
          
          <p className="final-thought">
            As I contemplate the future of AI in healthcare, Hippocratic AI's approach offers valuable 
            insights into creating systems that balance technical sophistication with ethical responsibility. Their work inspires me to think about how 
            similar approaches might revolutionize other critical domains where human expertise and AI capabilities can complement each other.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnderstandingStrategy; 