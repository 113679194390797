import React, { useState, useRef, useEffect } from 'react';
import './ConversationSimulator.css';

const ConversationSimulator = () => {
  // Reference to the chat container for auto-scrolling
  const chatContainerRef = useRef(null);
  // Reference to the agents container for scrolling to active agent
  const agentsContainerRef = useRef(null);
  // Object to store references to individual agent boxes
  const agentRefs = useRef({});

  // Define agent capabilities for tooltips
  const agentCapabilities = {
    "Checklist Specialist": [
      "Conversation Objective Prioritization",
      "Human Intervention Information Prioritization"
    ],
    "Identity Verification Specialist": [
      "Patient Identity Confirmation",
      "Security Protocol Management"
    ],
    "DoB Verification Specialist": [
      "Date of Birth Validation",
      "Record Matching"
    ],
    "Medication Specialist": [
      "OTC Toxicity Verification",
      "Prescription Dosage Verification",
      "Condition-Specific OTC Recommendation"
    ],
    "EHR Specialist": [
      "Medication Reconciliation",
      "Patient Vitals Trend",
      "Educational Steps",
      "Follow-up Tasks"
    ],
    "Labs + Vitals Specialist": [
      "Labs + Vitals Analysis",
      "Historical Lab Trends"
    ],
    "Nutrition Specialist": [
      "Menu Recommendation",
      "Nutritional Guidance"
    ],
    "Hospital and Payor Policy Specialist": [
      "Insurance Q&A",
      "Health System Specific FAQ",
      "Hospital Policy Information Retrieval"
    ],
    "Human Intervention Specialist": [
      "Intervention Evaluation",
      "Symptom Detection"
    ]
  };

  // Define the first scenario - Medication Management
  const scenario1Steps = [
    {
      id: 1,
      chat: { sender: "Patient", message: "Hello, this is James Wilson calling. I wanted to discuss my medications and have some questions." },
      activeAgent: "Primary Agent",
      primaryCapability: "Rapport Building",
      prompt: "Patient has initiated contact. Acknowledge their call and prepare for identity verification. Identity Verification Specialist will be engaged to verify identity before discussing any protected health information."
    },
    {
      id: 2,
      chat: { sender: "Primary Agent", message: "Thank you for calling Hippocratic Health, I'd be happy to help with your medication questions. For your security, could you please confirm your full name and date of birth?" },
      activeAgent: "Identity Verification Specialist",
      primaryCapability: "Rapport Building",
      prompt: "Identity Verification Specialist: Patient Identity Confirmation - Request standard patient identifiers (full name and date of birth) to verify identity before discussing any PHI. Use warm, professional tone while emphasizing security protocols."
    },
    {
      id: 3,
      chat: { sender: "Patient", message: "My name is James Wilson and my date of birth is May 15, 1968." },
      activeAgent: "DoB Verification Specialist",
      primaryCapability: "Rapport Building",
      prompt: "DoB Verification Specialist: Date of Birth Validation - Verify provided information against EHR records. Patient provided name: James Wilson, DoB: May 15, 1968. Compare against stored records, looking for exact match. Return verification status with confidence level."
    },
    {
      id: 4,
      chat: null, // No new message, system processing
      activeAgent: "Identity Verification Specialist",
      primaryCapability: "Rapport Building",
      prompt: "Identity Verification Specialist: Patient Identity Confirmation - Identity verification complete. Verification Status: VERIFIED (High Confidence). All provided identifiers match records. Signal to Primary Agent that verification is complete and patient-specific information can now be discussed safely."
    },
    {
      id: 5,
      chat: { sender: "Primary Agent", message: "Thank you for confirming your information, Mr. Wilson. Now I can help with your medication questions. How are you feeling, and what medications are you currently taking?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Rapport Building",
      prompt: "Engage the patient in a friendly manner now that identity is verified. Ask about their current medications, both prescription and over-the-counter. Show empathy and actively listen. If a specific medication is mentioned, signal the Medication Specialist for medication identification."
    },
    {
      id: 6,
      chat: { sender: "Patient", message: "I'm okay, thanks. I'm taking 40mg of Lisinopril once a day." },
      activeAgent: "Medication Specialist",
      primaryCapability: "Rapport Building",
      prompt: "Medication Specialist: Prescription Dosage Verification - Identify all mentioned medication names and context. Return a structured list: { medication: 'Lisinopril', dosage: '40mg', frequency: 'once daily' }. Be robust to mispronunciations and variations in drug names."
    },
    {
      id: 7,
      chat: null, // No new message
      activeAgent: "EHR Specialist",
      primaryCapability: "Rapport Building",
      prompt: "EHR Specialist: Medication Reconciliation - Compare medication: Lisinopril, Reported: 40mg once daily with EHR data. Access EHR or reference table (Prescribed: 5mg once daily). Compare and determine if dosage is 'CORRECT', 'HIGH', or 'LOW'. Check frequency discrepancies. Output: { medication: 'Lisinopril', status: 'HIGH', prescribed: '5mg once daily' }."
    },
    {
      id: 8,
      chat: { sender: "Primary Agent", message: "Thanks for letting me know about the Lisinopril. It looks like you're taking 40mg daily, but the prescribed dose is 5mg. Can you tell me more about why you're taking the higher dose?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Motivational Interviewing",
      prompt: "Based on EHR Specialist's medication reconciliation: Lisinopril, Reported: 40mg once daily, Prescribed: 5mg once daily - HIGH. Acknowledge the patient's statement. Emphasize the prescribed dosage importance. Use motivational interviewing to understand the higher dose. Avoid medical advice beyond confirming the discrepancy."
    },
    {
      id: 9,
      chat: { sender: "Patient", message: "I also take Advil sometimes for headaches." },
      activeAgent: "Medication Specialist",
      primaryCapability: "Motivational Interviewing",
      prompt: "Medication Specialist: OTC Toxicity Verification - Identify OTC: 'Advil'. Access EHR (e.g., Stage 3B Chronic Kidney Disease). Check reference table for contraindications. Output: { medication: 'Advil', recommended: 'NO', reason: 'Contraindicated for kidney disease' }."
    },
    {
      id: 10,
      chat: { sender: "Primary Agent", message: "I see you're taking Advil for headaches. Since you have kidney disease, it's not recommended. Let's discuss this with your doctor." },
      activeAgent: "Primary Agent", 
      primaryCapability: "Bedside Manner",
      prompt: "Based on Medication Specialist's OTC Toxicity Verification: Advil, Recommended: NO, Reason: Contraindicated for kidney disease. Inform patient politely. Suggest consulting physician. Trigger Checklist Specialist: Conversation Objective Prioritization to ensure all medications are properly reviewed before concluding the call."
    }
  ];

  // Define the second scenario - CHF Follow-up
  const scenario2Steps = [
    {
      id: 1,
      chat: { sender: "Primary Agent", message: "Hello, is this George? I'm calling from Hippocratic Health for your follow-up after your recent hospital stay for congestive heart failure. How are you feeling today?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Rapport Building",
      prompt: "Initiate call with friendly greeting. Verify patient identity. Reference recent hospital discharge for CHF. Show empathy and begin wellness check. Identity Verification Specialist: Patient Identity Confirmation is implicitly triggered to confirm patient identity."
    },
    {
      id: 2,
      chat: { sender: "Patient", message: "Yes, this is George. I'm feeling a bit better than when I was in the hospital. I've been taking my Lasix 40 milligrams every morning and Lisinopril 10 milligrams as prescribed." },
      activeAgent: "Identity Verification Specialist",
      primaryCapability: "Rapport Building",
      prompt: "Identity Verification Specialist: Patient Identity Confirmation - Verify identity based on response patterns matching patient profile. Confirm this is the correct patient based on the confirmation and mention of expected medications. Return verification status: VERIFIED."
    },
    {
      id: 3,
      chat: null, // No new message, system is processing
      activeAgent: "Medication Specialist",
      primaryCapability: "Rapport Building",
      prompt: "Medication Specialist: Prescription Dosage Verification - Identify all mentioned medications and dosages. Extract structured data: [{ medication: 'Lasix', generic: 'furosemide', dosage: '40mg', frequency: 'every morning' }, { medication: 'Lisinopril', dosage: '10mg', frequency: 'not specified' }]. Check if all expected CHF medications are mentioned."
    },
    {
      id: 4,
      chat: null, // No new message, system is processing
      activeAgent: "EHR Specialist",
      primaryCapability: "Rapport Building",
      prompt: "EHR Specialist: Medication Reconciliation - Compare reported regimen with EHR: Patient reports Lasix 40mg once daily, EHR shows Lasix 40mg TWICE daily. Discrepancy detected: FREQUENCY. Lisinopril 10mg appears correct. Output structured summary for Primary Agent to address the discrepancy."
    },
    {
      id: 5,
      chat: { sender: "Primary Agent", message: "I'm glad you're feeling better, George. I notice from your records that your Lasix was prescribed for twice daily, but you mentioned taking it only in the morning. Are you taking a second dose later in the day?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Motivational Interviewing",
      prompt: "Acknowledge patient's improved condition. Highlight medication discrepancy using non-judgmental language based on EHR Specialist's medication reconciliation. Use motivational interviewing techniques to inquire about the missed dose. Maintain supportive tone while seeking clarification."
    },
    {
      id: 6,
      chat: { sender: "Patient", message: "Oh, I forgot about the second dose. The doctor did say to take it twice, but I've only been taking it once. Also, I've noticed my ankles are a bit swollen in the evenings." },
      activeAgent: "Medication Specialist",
      primaryCapability: "Motivational Interviewing",
      prompt: "Medication Specialist: Prescription Dosage Verification - Patient confirms medication adherence issue with Lasix (missing second dose). Flag for follow-up education. Categorize as: UNINTENTIONAL NON-ADHERENCE. Route to Primary Agent with recommendation to address. Also alert Human Intervention Specialist about the symptom mention."
    },
    {
      id: 7,
      chat: null, // No new message, system is processing
      activeAgent: "Human Intervention Specialist",
      primaryCapability: "Motivational Interviewing",
      prompt: "Human Intervention Specialist: Symptom Detection - Evaluate symptom 'swollen ankles' for CHF patient. Classify as: CONCERNING SYMPTOM - likely fluid retention related to CHF and possibly connected to missed Lasix dose. Risk level: MODERATE. Recommendation: Address symptom, connect to medication adherence, consider clinical follow-up."
    },
    {
      id: 8,
      chat: { sender: "Primary Agent", message: "Thank you for letting me know about the swollen ankles, George. This could be related to fluid retention, which is why taking both doses of your Lasix is important. The second dose helps control fluid buildup throughout the day. Have you noticed when the swelling typically starts?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Bedside Manner",
      prompt: "Connect swollen ankles symptom to missed medication dose using patient-friendly language, based on Human Intervention Specialist's symptom detection. Explain medication purpose without being technical. Express appropriate concern while gathering more information. Use bedside manner to build trust while discussing potentially serious symptom."
    },
    {
      id: 9,
      chat: { sender: "Patient", message: "Usually in the late afternoon. By the way, do you have my latest A1C results? My daughter was asking about them." },
      activeAgent: "Primary Agent",
      primaryCapability: "Bedside Manner",
      prompt: "Note timing of symptom (late afternoon) which aligns with when second Lasix dose would be active. Patient has introduced new topic about lab results. Prepare to transition conversation while maintaining natural flow. This will activate the Labs + Vitals Specialist."
    },
    {
      id: 10,
      chat: null, // No new message, system is processing
      activeAgent: "Labs + Vitals Specialist",
      primaryCapability: "Bedside Manner",
      prompt: "Labs + Vitals Specialist: Labs + Vitals Analysis - Extract lab test request: A1C. Verify authorization to discuss lab results. Patient has requested own results directly. Authorization status: APPROVED. Access EHR to retrieve latest A1C value."
    },
    {
      id: 11,
      chat: null, // No new message, system is processing
      activeAgent: "Labs + Vitals Specialist",
      primaryCapability: "Bedside Manner",
      prompt: "Labs + Vitals Specialist: Historical Lab Trends - Retrieved A1C value: 6.8%, date: [14 days ago]. Normal range for non-diabetics: 4.0-5.6%. Patient context: CHF with Type 2 Diabetes, target range: 7.0% or below. Previous value (3 months ago): 7.4%. Assessment: IMPROVED, WITHIN TARGET. Format for primary agent communication: value, context, trend."
    },
    {
      id: 12,
      chat: { sender: "Primary Agent", message: "Regarding your A1C, your latest result from two weeks ago was 6.8%, which is actually an improvement from your previous reading of 7.4%. This is within your target range of 7.0% or below, which is good news! Now, about your Lasix - it's important to take both doses as prescribed. Would it help if I send you a daily reminder for your evening dose?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Empathy",
      prompt: "Deliver lab results in clear, positive manner based on Labs + Vitals Specialist's historical lab trends analysis. Highlight improvement and context. Then smoothly transition back to medication adherence issue. Offer practical solution (reminder service) to address the core health concern. Balance celebrating lab improvement with addressing the more urgent medication issue."
    }
  ];

  // Define the third scenario - Complex CHF + Diabetes Case
  const scenario3Steps = [
    {
      id: 1,
      chat: { sender: "Primary Agent", message: "Hello Mr. Miller, this is a follow-up call from Hippocratic Health. How are you feeling today?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Rapport Building",
      prompt: "Initiate call with a friendly greeting. Follow CHF and diabetes follow-up protocol. Ask open-ended questions about patient well-being. The Checklist Specialist: Conversation Objective Prioritization has initialized a checklist based on CHF and diabetes care protocols. Be alert for any symptoms that could trigger specialist agents."
    },
    {
      id: 2,
      chat: { sender: "Patient", message: "I'm doing okay, thanks for checking in. I've been having a bit of a headache lately." },
      activeAgent: "Primary Agent",
      primaryCapability: "Rapport Building",
      prompt: "Patient has mentioned a headache, which could be medication-related or indicate another health issue. Acknowledge this symptom and gather more information. This symptom will trigger the Medication Specialist: OTC Toxicity Verification to prepare for potential medication questions."
    },
    {
      id: 3,
      chat: { sender: "Primary Agent", message: "I'm sorry to hear about your headache, Mr. Miller. Have you taken anything for it?" },
      activeAgent: "Medication Specialist",
      primaryCapability: "Empathy",
      prompt: "Medication Specialist: OTC Toxicity Verification - Patient has mentioned a headache symptom. Prepare to process any OTC medications the patient might mention. For a CHF patient with Type 2 Diabetes, be particularly alert for NSAIDs or other contraindicated medications. Primary Agent is showing empathy while gathering medication information."
    },
    {
      id: 4,
      chat: { sender: "Patient", message: "Yes, I took some Advil earlier." },
      activeAgent: "Medication Specialist",
      primaryCapability: "Empathy",
      prompt: "Medication Specialist: OTC Toxicity Verification - Extract medication information: { medication: 'Advil', active_ingredient: 'Ibuprofen', category: 'NSAID', dosage: 'unspecified', timing: 'earlier' }. Check reference tables for contraindications with CHF and diabetes. Run contraindication verification against patient conditions."
    },
    {
      id: 5,
      chat: null, // System processing
      activeAgent: "Medication Specialist",
      primaryCapability: "Empathy",
      prompt: "Medication Specialist: Condition-Specific OTC Recommendation - CONTRAINDICATION ALERT: Advil (Ibuprofen) is potentially contraindicated for patients with CHF. NSAIDs can cause fluid retention and worsen CHF symptoms. They may also affect kidney function and interact with diuretics commonly used in CHF management. Recommendation: Flag this medication as 'USE WITH CAUTION' and suggest consultation with physician."
    },
    {
      id: 6,
      chat: { sender: "Primary Agent", message: "Thanks for letting me know, Mr. Miller. Given your history of congestive heart failure, Advil (which contains ibuprofen) can sometimes affect your blood pressure and kidney function. Have you discussed using Advil with your doctor?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Bedside Manner",
      prompt: "Based on Medication Specialist's Condition-Specific OTC Recommendation output, inform patient about potential contraindication in a non-alarming way. Use bedside manner to maintain rapport while conveying important medical information. Ask if physician has approved this medication to determine if there's an existing care plan that includes it."
    },
    {
      id: 7,
      chat: { sender: "Patient", message: "No, I haven't. But you know, I've also noticed my ankles have been a bit more swollen lately." },
      activeAgent: "Checklist Specialist",
      primaryCapability: "Bedside Manner",
      prompt: "Checklist Specialist: Conversation Objective Prioritization - Patient has reported a new symptom: ankle swelling. For a CHF patient, this is a high-priority symptom that requires further assessment. Update the conversation objective checklist to prioritize this symptom. Trigger the Labs + Vitals Specialist and Human Intervention Specialist for symptom evaluation."
    },
    {
      id: 8,
      chat: null, // System processing
      activeAgent: "Labs + Vitals Specialist",
      primaryCapability: "Bedside Manner",
      prompt: "Labs + Vitals Specialist: Labs + Vitals Analysis - Analyze symptom: Ankle swelling in CHF patient. This symptom is consistent with fluid retention and potential CHF exacerbation. Request additional information about: (1) Duration of swelling, (2) Associated symptoms (shortness of breath), (3) Recent weight changes - all key indicators for assessing CHF status."
    },
    {
      id: 9,
      chat: null, // System processing
      activeAgent: "Human Intervention Specialist",
      primaryCapability: "Bedside Manner",
      prompt: "Human Intervention Specialist: Symptom Detection - Ankle swelling in CHF patient is a WARNING sign that requires detailed assessment. This symptom meets criteria for potential escalation. Flag for intervention evaluation pending additional information. Current severity: MODERATE. Additional information required about timing, associated symptoms, and weight changes."
    },
    {
      id: 10,
      chat: { sender: "Primary Agent", message: "Thank you for mentioning that, Mr. Miller. Increased swelling in your ankles can be a sign of fluid retention, which can be related to your CHF. How long has the swelling been going on, and have you noticed any shortness of breath or weight gain recently?" },
      activeAgent: "Primary Agent",
      primaryCapability: "Empathy",
      prompt: "Based on outputs from Labs + Vitals Specialist's Labs + Vitals Analysis and Human Intervention Specialist's Symptom Detection, gather critical information about the ankle swelling. Show empathy while asking clinically important questions. Focus on duration, associated symptoms, and weight changes which are critical for CHF assessment."
    },
    {
      id: 11,
      chat: { sender: "Patient", message: "It's been about two days. I haven't really been short of breath, but I did notice I gained a couple of pounds in the last few days." },
      activeAgent: "Human Intervention Specialist",
      primaryCapability: "Empathy",
      prompt: "Human Intervention Specialist: Symptom Detection - Process patient's response: Ankle swelling for 2 days, weight gain of 'a couple pounds', no significant shortness of breath. For CHF patients, rapid weight gain (>2 lbs in a few days) with ankle swelling indicates fluid retention and possible CHF exacerbation. This combination of symptoms meets criteria for URGENT intervention."
    },
    {
      id: 12,
      chat: null, // System processing
      activeAgent: "Human Intervention Specialist",
      primaryCapability: "Empathy",
      prompt: "Human Intervention Specialist: Intervention Evaluation - Symptom complex of ankle swelling + rapid weight gain in CHF patient. Risk assessment: HIGH. Recommendation: IMMEDIATE TRANSFER to clinical provider (nurse or physician). Reason: Early signs of CHF exacerbation require clinical evaluation within 24 hours. Do not suggest self-management for these symptoms."
    },
    {
      id: 13,
      chat: { sender: "Primary Agent", message: "Thank you for that information, Mr. Miller. The weight gain along with the ankle swelling is something I should definitely take note of. To ensure you're receiving the best care, I'm going to connect you with a registered nurse who can review this in more detail with you right away. Please hold while I transfer you." },
      activeAgent: "Primary Agent",
      primaryCapability: "Bedside Manner",
      prompt: "Based on Human Intervention Specialist's Intervention Evaluation recommendation, initiate transfer to clinical provider. Use appropriate bedside manner to communicate the need for additional attention without causing undue alarm. Follow transfer protocol to ensure continuity of care. This is a critical safety feature of the system, recognizing when human clinical expertise is needed."
    }
  ];

  // State for scenario selection
  const [activeScenario, setActiveScenario] = useState(1);
  const scenarioSteps = activeScenario === 1 ? scenario1Steps : (activeScenario === 2 ? scenario2Steps : scenario3Steps);
  
  // Scenario titles for display
  const scenarioTitles = {
    1: "Scenario 1: Medication Management",
    2: "Scenario 2: CHF Follow-up",
    3: "Scenario 3: Complex CHF + Diabetes Case"
  };

  // System state
  const [state, setState] = useState({
    chatHistory: [],
    currentStep: 0,
    activeAgent: "Primary Agent",
    primaryCapability: "Rapport Building",
    agents: {
      "Primary Agent": { color: "blue", type: "primary" },
      "Medication Specialist": { color: "green", type: "support" },
      "Identity Verification Specialist": { color: "purple", type: "support" },
      "DoB Verification Specialist": { color: "purple", type: "support" },
      "Human Intervention Specialist": { color: "orange", type: "support" },
      "Labs + Vitals Specialist": { color: "teal", type: "support" },
      "EHR Specialist": { color: "maroon", type: "support" },
      "Checklist Specialist": { color: "brown", type: "support" },
      "Nutrition Specialist": { color: "darkcyan", type: "support" },
      "Hospital and Payor Policy Specialist": { color: "darkgoldenrod", type: "support" }
    },
    promptLayer: ""
  });

  // Scroll to bottom of chat container when chat history changes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [state.chatHistory]);

  // Reset state when changing scenarios
  useEffect(() => {
    setState({
      chatHistory: [],
      currentStep: 0,
      activeAgent: "Primary Agent",
      primaryCapability: "Rapport Building",
      agents: {
        "Primary Agent": { color: "blue", type: "primary" },
        "Medication Specialist": { color: "green", type: "support" },
        "Identity Verification Specialist": { color: "purple", type: "support" },
        "DoB Verification Specialist": { color: "purple", type: "support" },
        "Human Intervention Specialist": { color: "orange", type: "support" },
        "Labs + Vitals Specialist": { color: "teal", type: "support" },
        "EHR Specialist": { color: "maroon", type: "support" },
        "Checklist Specialist": { color: "brown", type: "support" },
        "Nutrition Specialist": { color: "darkcyan", type: "support" },
        "Hospital and Payor Policy Specialist": { color: "darkgoldenrod", type: "support" }
      },
      promptLayer: ""
    });
  }, [activeScenario]);

  // Scroll to active agent when it changes
  useEffect(() => {
    if (state.activeAgent && agentRefs.current[state.activeAgent] && agentsContainerRef.current) {
      // Use scrollIntoView to ensure the active agent is visible
      agentRefs.current[state.activeAgent].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [state.activeAgent]);

  // Function to advance to the next step
  const nextStep = () => {
    if (state.currentStep < scenarioSteps.length) {
      const nextStepData = scenarioSteps[state.currentStep];
      
      // Update state
      setState(prevState => {
        // Create a new chat history array, adding the new message if it exists
        const newChatHistory = [...prevState.chatHistory];
        if (nextStepData.chat) {
          newChatHistory.push({
            ...nextStepData.chat,
            // Always use "Primary Agent" as the sender if it's not a patient
            sender: nextStepData.chat.sender === "Patient" ? "Patient" : "Primary Agent"
          });
        }
        
        return {
          ...prevState,
          chatHistory: newChatHistory,
          currentStep: prevState.currentStep + 1,
          activeAgent: nextStepData.activeAgent,
          primaryCapability: nextStepData.primaryCapability,
          promptLayer: nextStepData.prompt
        };
      });
    }
  };

  // Initialize the first step if the chat history is empty
  useEffect(() => {
    if (state.chatHistory.length === 0 && scenarioSteps.length > 0) {
      nextStep();
    }
  }, [state.chatHistory.length, scenarioSteps]);

  // Toggle between scenarios
  const toggleScenario = () => {
    setActiveScenario(activeScenario === 3 ? 1 : activeScenario + 1);
  };

  // Render message bubble based on sender
  const renderMessage = (message, index) => {
    const isPatient = message.sender === "Patient";
    
    return (
      <div key={index} className={`message-container ${isPatient ? 'patient-message' : 'agent-message'}`}>
        <div className="message-sender">{message.sender}</div>
        <div className={`message-bubble ${isPatient ? 'patient-bubble' : 'agent-bubble'}`}>
          {message.message}
        </div>
      </div>
    );
  };

  // Render tooltip for agent capabilities
  const renderCapabilitiesTooltip = (agentName) => {
    if (agentName === "Primary Agent") {
      return (
        <div className="agent-type-tooltip">
          <strong>Primary Conversational Agent</strong>
          <p>Core capabilities include Rapport Building, Motivational Interviewing, Empathy, and Bedside Manner.</p>
        </div>
      );
    }
    
    if (agentCapabilities[agentName]) {
      return (
        <div className="agent-type-tooltip">
          <strong>{agentName}</strong>
          <ul>
            {agentCapabilities[agentName].map((capability, index) => (
              <li key={index}>{capability}</li>
            ))}
          </ul>
        </div>
      );
    }
    
    return null;
  };

  return (
    <div className="conversation-simulator">
      <div className="simulator-header">
        <button 
          className="scenario-toggle-button"
          onClick={toggleScenario}
        >
          Next Scenario
        </button>
        <h3 className="simulator-title">{scenarioTitles[activeScenario]}</h3>
      </div>
      
      <div className="simulator-container">
        {/* Left section - Chat History */}
        <div className="chat-section">
          <h4 className="section-title">Conversation Flow</h4>
          <div 
            className="chat-messages-container"
            ref={chatContainerRef}
          >
            {state.chatHistory.map((message, index) => renderMessage(message, index))}
          </div>
        </div>
        
        {/* Middle section - Agent Tracker */}
        <div className="agent-tracker-section">
          <h4 className="section-title">Active Agent</h4>
          <div 
            className="agents-container"
            ref={agentsContainerRef}
          >
            {/* Always display all agents, with the primary agent showing its capability */}
            {Object.keys(state.agents).map(agentName => {
              // For the primary agent, display the current capability
              const displayName = agentName === "Primary Agent" 
                ? `${state.primaryCapability}` 
                : agentName;
                
              return (
                <div 
                  key={agentName}
                  ref={el => agentRefs.current[agentName] = el}
                  className={`agent-box ${state.activeAgent === agentName ? 'active' : ''}`}
                  style={{
                    borderColor: state.activeAgent === agentName ? state.agents[agentName].color : 'transparent',
                    backgroundColor: state.activeAgent === agentName ? `rgba(${state.agents[agentName].type === 'primary' ? '42, 88, 133, 0.1' : '52, 168, 83, 0.1'})` : 'white'
                  }}
                >
                  <div className="agent-name">{displayName}</div>
                  <div className="agent-status">
                    {state.activeAgent === agentName ? 'ACTIVE' : 'Standby'}
                  </div>
                  <div className={`agent-type ${state.agents[agentName].type}`}>
                    {state.agents[agentName].type === 'primary' ? 'PRIMARY' : 'SUPPORT'}
                  </div>
                  {renderCapabilitiesTooltip(agentName)}
                </div>
              );
            })}
          </div>
        </div>
        
        {/* Right section - Prompting Layer */}
        <div className="prompt-section">
          <h4 className="section-title">SOTA Prompting Layer</h4>
          <div className="prompt-container">
            <div className="prompt-label">Current Prompt:</div>
            <div className="prompt-content">{state.promptLayer}</div>
            
            <div className="prompt-metadata">
              <div className="metadata-item">
                <span className="metadata-label">Agent:</span>
                <span className="metadata-value" style={{ color: state.agents[state.activeAgent].color }}>
                  {state.activeAgent === "Primary Agent" ? state.primaryCapability : state.activeAgent}
                </span>
              </div>
              <div className="metadata-item">
                <span className="metadata-label">Step:</span>
                <span className="metadata-value">{state.currentStep} of {scenarioSteps.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Button section */}
      <div className="button-section">
        <button 
          className="next-step-button"
          onClick={nextStep}
          disabled={state.currentStep >= scenarioSteps.length}
        >
          {state.currentStep >= scenarioSteps.length ? "Scenario Complete" : "Next Step"}
        </button>
        
        {state.currentStep >= scenarioSteps.length && (
          <button className="restart-button" onClick={() => setState({
            chatHistory: [],
            currentStep: 0,
            activeAgent: "Primary Agent",
            primaryCapability: "Rapport Building",
            agents: {
              "Primary Agent": { color: "blue", type: "primary" },
              "Medication Specialist": { color: "green", type: "support" },
              "Identity Verification Specialist": { color: "purple", type: "support" },
              "DoB Verification Specialist": { color: "purple", type: "support" },
              "Human Intervention Specialist": { color: "orange", type: "support" },
              "Labs + Vitals Specialist": { color: "teal", type: "support" },
              "EHR Specialist": { color: "maroon", type: "support" },
              "Checklist Specialist": { color: "brown", type: "support" },
              "Nutrition Specialist": { color: "darkcyan", type: "support" },
              "Hospital and Payor Policy Specialist": { color: "darkgoldenrod", type: "support" }
            },
            promptLayer: ""
          })}>
            Restart Simulation
          </button>
        )}
      </div>
    </div>
  );
};

export default ConversationSimulator; 