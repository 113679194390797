import React, { useState } from 'react';
import './PolarisArchitectureDiagram.css';

const PolarisArchitectureDiagram = () => {
  const [hoveredElement, setHoveredElement] = useState(null);

  // Define the support agents and their capabilities
  const supportAgents = {
    checklist: {
      name: 'Checklist Specialist',
      capabilities: [
        'Conversation Objective Prioritization',
        'Human Intervention Information Prioritization'
      ]
    },
    privacy: {
      name: 'Privacy & Compliance Specialist',
      capabilities: [
        'Identity Verification',
        'DoB Verification'
      ]
    },
    medication: {
      name: 'Medication Specialist',
      capabilities: [
        'OTC Toxicity Verification',
        'Prescription Dosage Verification',
        'Condition-Specific OTC Recommendation'
      ]
    },
    ehr: {
      name: 'EHR Specialist',
      capabilities: [
        'Medication Reconciliation',
        'Patient Vitals Trend',
        'Educational Steps',
        'Follow-up Tasks'
      ]
    },
    labs: {
      name: 'Labs + Vitals Specialist',
      capabilities: [
        'Labs + Vitals Analysis',
        'Historical Lab Trends'
      ]
    },
    nutrition: {
      name: 'Nutrition Specialist',
      capabilities: [
        'Menu Recommendation',
        'Nutritional Guidance'
      ]
    },
    hospital: {
      name: 'Hospital and Payor Policy Specialist',
      capabilities: [
        'Insurance Q&A',
        'Health System Specific FAQ',
        'Hospital Policy Information Retrieval'
      ]
    },
    intervention: {
      name: 'Human Intervention Specialist',
      capabilities: [
        'Intervention Evaluation',
        'Symptom Detection'
      ]
    }
  };

  // Primary agent capabilities
  const primaryAgentCapabilities = [
    'Healthy Lifestyle Guidance',
    'Rapport Building',
    'Motivational Interviewing',
    'Empathy',
    'Bedside Manner'
  ];

  // Handle hover events
  const handleHover = (element) => {
    setHoveredElement(element);
  };

  const handleHoverEnd = () => {
    setHoveredElement(null);
  };

  // Helper function to determine if an element is highlighted
  const isHighlighted = (element) => {
    if (!hoveredElement) return false;
    
    // Define connections between agents
    const connections = {
      'primary': ['medication', 'labs', 'privacy', 'ehr', 'nutrition', 'intervention', 'checklist', 'hospital'],
      'medication': ['primary', 'ehr'],
      'labs': ['primary', 'ehr'],
      'privacy': ['primary'],
      'ehr': ['primary', 'medication', 'labs'],
      'nutrition': ['primary'],
      'intervention': ['primary'],
      'checklist': ['primary'],
      'hospital': ['primary']
    };
    
    return hoveredElement === element || 
           (connections[hoveredElement] && connections[hoveredElement].includes(element));
  };

  return (
    <div className="polaris-architecture-wrapper">
      <div className="polaris-architecture-diagram">
        {/* Left Section - Patient Flow */}
        <div className="diagram-section patient-flow-section">
          <h3 className="section-title">Patient Interaction</h3>
          
          <div className="patient-flow-content">
            <div className="flow-steps">
              <div className="flow-step">
                <div className="flow-node">Patient Speaks</div>
                <div className="flow-arrow-down"></div>
              </div>
              <div className="flow-step">
                <div className="flow-box">Speech Transcription</div>
                <div className="flow-arrow-down"></div>
              </div>
              <div className="flow-step">
                <div className="flow-box-highlight">Polaris</div>
                <div className="flow-arrow-down"></div>
              </div>
              <div className="flow-step">
                <div className="flow-box">Text-to-Speech</div>
                <div className="flow-arrow-down"></div>
              </div>
              <div className="flow-step">
                <div className="flow-node">AI Speaks</div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Middle Section - Primary Agent */}
        <div className={`diagram-section primary-agent-section ${isHighlighted('primary') ? 'highlighted' : ''}`}
             onMouseEnter={() => handleHover('primary')} 
             onMouseLeave={handleHoverEnd}>
          <h3 className="section-title">PRIMARY AGENT</h3>
          
          <div className="primary-agent-content">
            <h4 className="agent-subtitle">Core Conversational Capabilities</h4>
            
            <div className="capabilities-list">
              {primaryAgentCapabilities.map((capability, index) => (
                <div key={`primary-${index}`} className="capability-pill">
                  {capability}
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Right Section - Support Agents */}
        <div className="diagram-section support-agents-section">
          <h3 className="section-title">SUPPORT AGENTS</h3>
          
          <div className="support-agents-content">
            <div className="support-agents-wrapper">
              {Object.entries(supportAgents).map(([key, agent]) => (
                <div 
                  key={key}
                  className={`support-agent ${isHighlighted(key) ? 'highlighted' : ''}`}
                  onMouseEnter={() => handleHover(key)} 
                  onMouseLeave={handleHoverEnd}
                >
                  <h4 className="support-agent-title">{agent.name}</h4>
                  <div className="support-capabilities">
                    {agent.capabilities.map((capability, index) => (
                      <div key={`${key}-${index}`} className="support-capability-pill">
                        {capability}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Connection lines - These are drawn with CSS */}
        <div className="connection-lines">
          {Object.keys(supportAgents).map((agentKey) => (
            <div 
              key={`connection-${agentKey}`}
              className={`connection-line connection-${agentKey} ${(isHighlighted('primary') && isHighlighted(agentKey)) ? 'highlighted' : ''}`}
            ></div>
          ))}
        </div>
      </div>
      
      {/* Caption and Legend Container */}
      <div className="caption-legend-container">
        {/* Diagram Caption */}
        <div className="diagram-caption">
          <h4 className="caption-title">Hippocratic AI's Multi-Agent Architecture</h4>
          <p className="caption-description">
            This diagram illustrates Polaris system architecture, showing how specialized agents collaborate to deliver safe healthcare conversations.
          </p>
        </div>
        
        {/* Model Information Legend */}
        <div className="model-info-legend">
          <h4 className="legend-title">Model Parameters Information</h4>
          <div className="model-info-list">
            <div className="model-info-item">
              <div className="color-indicator"></div>
              <span>Primary Agent: <span className="model-info-highlight">70B-100B parameters</span> (Polaris 1.0), 6x larger in Polaris 2.0</span>
            </div>
            <div className="model-info-item">
              <div className="color-indicator"></div>
              <span>Support Agents: <span className="model-info-highlight">50B-100B parameters</span> (Polaris 1.0)</span>
            </div>
            <div className="model-info-item">
              <div className="color-indicator"></div>
              <span>Polaris 2.0: <span className="model-info-highlight">3+ trillion parameters</span> (3x increase over Polaris 1.0)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolarisArchitectureDiagram; 