import { useEffect } from 'react';

export function MicrosoftRedirect() {
    useEffect(() => {
        window.location.href = 'https://www.microsoft.com';
    }, []);

    return (
        <div className="flex items-center justify-center h-screen">
            <p>Redirecting...</p>
        </div>
    );
} 