import React from 'react';
import './App.css';
import { Navbar } from './components/Navbar.js';
import { Route, Routes, useLocation } from 'react-router-dom';
import { WorkPage } from './pages/WorkPage';
import { AboutPage } from './pages/AboutPage'
import { EuropeanTT } from './pages/projects/EuropeanTT';
import { JodtPage } from './pages/projects/JodtProjectPage';
import { ConstructionPage } from './pages/ConstructionPage';
import { PhoneScroll } from './pages/iPhoneScroll';
import SpotifyDesignCase from './pages/SpotifyDesignCaseCode';
import { Footer } from './components/Footer';
import { YouTubeEdTech } from './pages/YouTubeEdTech';
import { MicrosoftRedirect } from './pages/MicrosoftRedirect';
import HippocraticAICaseStudy from './pages/hippocratic-ai-case-study/main';

function App() {
  const location = useLocation();
  const isHippocraticAIPage = location.pathname === '/hippocratic-ai';

  // Apply different styling for Hippocratic AI page
  const containerStyle = isHippocraticAIPage 
    ? { 
        width: '100%', 
        height: '100%',
        overflow: 'auto',
        backgroundColor: '#f5f5f7'
      } 
    : {
        transform: 'scale(1.5)',
        transformOrigin: 'top left',
        width: '66.6666666%', // 100% / 1.5 to maintain the same visible area
        height: '66.66666%', // 100% / 1.5 to maintain the same visible area
        overflow: 'auto'
      };

  return (
    <div className="app-container" style={containerStyle}>
      <div className={`body ${isHippocraticAIPage ? 'w-full' : 'w-full'}`}>
        {!isHippocraticAIPage && <Navbar/>}
        <Routes>
          <Route path='/' element={<WorkPage/>}/>
          <Route path='/resume' element={<div></div>}/>
          <Route path='/about' element={<AboutPage/>}/>
          <Route path='/construction' element={<ConstructionPage/>}/>
          <Route path='/EuropeanTT' element={<EuropeanTT/>}/>
          <Route path='/jodt' element={<JodtPage/>}/>
          <Route path='/SpotifyDesignCase' element={<SpotifyDesignCase/>}/>
          <Route path='/FEproject-1' element={<YouTubeEdTech />} />
          <Route path='/FEproject-2' element={<MicrosoftRedirect />} />
          <Route path='/hippocratic-ai' element={<HippocraticAICaseStudy />} />
        </Routes>
        {!isHippocraticAIPage && <Footer/>}
      </div>
    </div>
  );
}

export default App;