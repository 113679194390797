import React, { useState, useEffect } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import UnderstandingStrategy from './1-understanding-strategy';
import ApplyingExpertise from './2-applying-expertise';
import EnsuringSafety from './3-ensuring-safety';
import personalityPhoto from '../../imgs/pfp-professional.png'; // Import the profile picture

const HippocraticAICaseStudy = () => {
  const [activeSection, setActiveSection] = useState('intro');

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to scroll to a section
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 50,
        behavior: 'smooth'
      });
      setActiveSection(sectionId);
    }
  };

  return (
    <div className="hippocratic-ai-page">
      <div className="case-study-container">
        <section id="intro" className="intro-section">
          <h1>Interactive Case Study: Hippocratic AI Prompting</h1>
          
          <div className="article-meta">
            <div className="author-info">
              <img src={personalityPhoto} alt="Sathvik" className="author-image" />
              <div className="author-details">
                <h3 className="author-name">Sathvik Perkari</h3>
                <p className="author-title">AI & Software Engineer</p>
              </div>
            </div>
            <div className="article-date">
              <p>Published: March 12, 2025</p>
              <p>Last Updated: March 12, 2025</p>
            </div>
          </div>
          
          <div className="article-summary">
            <p className="summary-text">
            Hey Hippocratic AI Team! 👋

I'm incredibly excited about the work you're doing at the intersection of AI & Healthcare, especially your commitment to safety. I came across the Junior AI Engineer, Prompting role and wanted to take a deep dive into your agent architecture, conversational planning layer, and safety measures.

Check out my interactive case study where I break it all down—along with a short video introduction. Would love to hear your thoughts!            </p>
          </div>
          
          <div className="content-preview-container">
            <div className="toc-container">
              <h3 className="toc-title">Table of Contents</h3>
              <ul className="toc-list">
                <li className={activeSection === 'intro' ? 'active' : ''}>
                  <a href="#intro" onClick={(e) => { e.preventDefault(); scrollToSection('intro'); }}>
                    Introduction
                  </a>
                </li>
                <li className={activeSection === 'understanding' ? 'active' : ''}>
                  <a href="#understanding" onClick={(e) => { e.preventDefault(); scrollToSection('understanding'); }}>
                    1. Understanding Hippocratic AI's Strategy
                  </a>
                </li>
                <li className={activeSection === 'applying' ? 'active' : ''}>
                  <a href="#applying" onClick={(e) => { e.preventDefault(); scrollToSection('applying'); }}>
                    2. Exploring the State of the Art Conversational Planning Layer
                  </a>
                </li>
                <li className={activeSection === 'ensuring' ? 'active' : ''}>
                  <a href="#ensuring" onClick={(e) => { e.preventDefault(); scrollToSection('ensuring'); }}>
                    3. Ensuring Safety & Compliance - Do No Harm
                  </a>
                </li>
              </ul>
            </div>
            
            <div className="video-preview">
              <h3 className="video-title">Video Introduction</h3>
              <div className="video-container">
                <iframe 
                  width="100%" 
                  height="100%" 
                  src="https://www.youtube.com/embed/-cOaZYCGzeU" 
                  title="Hippocratic AI Prompting Strategy" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen>
                </iframe>
              </div>
            </div>
          </div>
        </section>

        <div className="case-study-content">
          <div id="understanding">
            <UnderstandingStrategy />
          </div>
          <div id="applying">
            <ApplyingExpertise />
          </div>
          <div id="ensuring">
            <EnsuringSafety />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HippocraticAICaseStudy;
